import React from "react";
import { Helmet } from "react-helmet";
import QRCode from "qrcode.react";

import Layout from "../layout";

function CartPage({ location }) {
  return (
    <Layout location={location}>
      <>
        <Helmet title="Stuntpuppy" />
        <main className="flex flex-col justify-center items-center h-screen">
          {location && location.state && location.state.checkoutUrl ? (
            <>
              <div className="my-3">Link to checkout on phone:</div>
              <QRCode value={location.state.checkoutUrl} />
              <div className="my-3">or</div>
              <a
                className="bg-sp-orange text-xl font-medium rounded-full text-white mt-2 px-8 py-2 ml-2"
                href={location.state.checkoutUrl}
              >
                Redirect here
              </a>
            </>
          ) : (
            <div>error</div>
          )}
        </main>
      </>
    </Layout>
  );
}

export default CartPage;
